body[data-theme="dark"] {  

   .slider-tab {
      background-color: #C2C4CC;
   }

   .title-styles {
      color: #D9D9D9;
   }

   .header-icon {
      color: #C2C4CC;
      opacity: 0.8;
   }

   .slider-image {
      border: 5px solid #C2C4CC;
   }

   .language {
      background-color: #494F66;
   }

   .link-href {
      color: #D9D9D9;
   }

   .project-date {
      background-color: #494F66;
      color: #D9D9D9;
   }

   header {
      background-color: #494F66;
      h1 {
         color: #D9D9D9;
      }
   }

   #about {
      background-color: #5C6380;
      h1 span {
         color: #D9D9D9;
         font-weight: bold;
      }
      .polaroid span {
         background:  #494F66;
      }
      .card {
         background: #494F66;
         color: #D9D9D9;
      }
   }

   #portfolio {
      background: #494F66;
      .section-title {
         color: #D9D9D9 !important;
         font-weight: bold;
      }
      .foto div {
         background: #5C6380;
      }
      .project-title-settings {
         color: #D9D9D9;
      }
   }

   #resume {
      filter: brightness(80%);
      background: #5C6380;
      .section-title {
         color: #D9D9D9 !important;
         font-weight: bold;
      }

      .experience-badge {
         background: #494F66 !important;
         color: #D9D9D9;
      }

      .main-badge {
         background: #494F66 !important;
         color: #D9D9D9;
      }

      .vertical-timeline-element-date {
         color: #D9D9D9;
      }

      .vertical-timeline-element-icon {
         background: #494F66 !important;
      }
      @media only screen and (max-width: 1169px) { 
         .vertical-timeline-element-date {
            color: black;
         }
      }
   }

   .modal-inside .modal-content {
      background: #494F66;
      color: #D9D9D9;
   }

   .close-icon {
      color: #D9D9D9;
   }
}