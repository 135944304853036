body[data-theme="light"] {
 
    .slider-tab {
       background-color: #D9E0D5;
    }

    .card {
        background: #D9E0D5;
     }
   
    .bg-primary {
      background-color: #D9E0D5 !important;
      color: black;
    }
}